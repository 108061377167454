<template>
  <div class="support-container">
    <SectionTitle title="Về chúng tôi" link="/" />
    <div class="support">
      <div
        v-for="item in INTRODUCE_LIST"
        :id="`support-${item?.name}`"
        :key="item.name"
        class="support__item"
        :class="item?.type"
        :to="item.url"
      >
        <template v-if="item.hide !== '0'">
          <NuxtLink :to="item.url" class="support__item--link">
            <CommonBaseImg class="icon" :src="item.icon" :alt="item.name" />
            <p class="text">{{ item.name }}</p>
          </NuxtLink>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getFooterAboutUs } from '@/constants/footer'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { INTRODUCE_LIST } = getFooterAboutUs()
</script>
<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/section-introduce/index.scss" />
